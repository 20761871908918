<template>
    <div class="w-full px-2 py-2 flex flex-wrap justify-center items-center bg-gradient-to-t to-10% from-evelleSecondary to-white relative">
        <div class="w-full flex-1">
            <NuxtLink class="block w-fit" to="/"  aria-label="Go to startpage">
                <NuxtImg alt="Evelle logo" src="Evelle_guld_logo5_bigger.webp" format="webp" class="max-w-[300px]"></NuxtImg>
            </NuxtLink>
        </div>
        <div class="flex flex-initial ">
            <ComponentBurgerButton class="hidden md:block" colorMode="primary"></ComponentBurgerButton>
            <ComponentNewsletterSignupMini class="md:hidden" v-if="true"></ComponentNewsletterSignupMini>
        </div>
    </div>
</template>

<script lang="ts">
import { useRouter } from 'nuxt/app';
import componentEmailSignUp from './componentEmailSignUp.vue';

import { useUserStore } from '@/stores/user/userStore'
import { role } from '@/stores/user/userStore';
import { storeToRefs } from 'pinia';

export default defineNuxtComponent({
    name: "componentHeader",
    components: { componentEmailSignUp },
    setup() {
        const routes = useRouter();
        const links = routes.getRoutes();
        const current = reactive(routes.currentRoute);
        const { user } = storeToRefs(useUserStore())
        const isAdmin = computed(() => (user.value.role == role.admin))
        return { links, current, isAdmin };
    },
})
</script>

<style lang="scss">
.router-link-active {
    @apply text-[#e3cd9a];
}
</style>